import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { CollectivePaymentTransactionItem } from '../../models/collectivePaymentTransactionItem';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-collectivepaymentransactiondialog',
    templateUrl: './collectivepaymenttransaction.dialog.component.html',
    styleUrls: ['./collectivepaymenttransaction.dialog.component.scss'],
    standalone: false
})
export class CollectivePaymentTransactionDialog implements OnInit {

  collectivePaymentTransactionDataSource: CollectivePaymentTransactionItem[] = [];
  hasCollectivePaymentTransactions = false;

  private selection = new SelectionModel<CollectivePaymentTransactionItem>(true, []);

  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;

  constructor(public dialogRef: MatDialogRef<CollectivePaymentTransactionDialog, CollectivePaymentTransactionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CollectivePaymentTransactionItem[]) {

    this.collectivePaymentTransactionDataSource = data;
    this.hasCollectivePaymentTransactions = this.collectivePaymentTransactionDataSource.length > 0;
  }

  ngOnInit(): void {
    // Initialization logic can be added here if needed
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.collectivePaymentTransactionDataSource.length;
    return numSelected === numRows;
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `ParkingTransactionId`, displayText: $localize`Transaction ID` },
    { visible: true, alwaysVisibility: false, fieldName: `Amount`, displayText: $localize`Amount` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingDate`, displayText: $localize`Parking Date` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingLocationDescription`, displayText: $localize`Parking Location` },
    { visible: true, alwaysVisibility: false, fieldName: `VehicleNumber`, displayText: $localize`License Plate` },
  ];

}