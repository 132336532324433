import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

const scopesAll : string[] = [
  'openid',
  'profile',
];


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {

  returnUrl: string;
  submitted: boolean;
  loading: boolean;

  constructor(
    private authService: MsalService) {
  }

  ngOnInit() {
    // Initialization logic can be added here if needed
  }

  login() {
    const requestObj = {
      scopes: scopesAll
    };
    this.authService.loginRedirect(requestObj);
  }
}
