import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { ParkingTransactionService } from 'src/app/core/services/parkingTransaction.service';
import { ParkingTransactionHistoryService } from 'src/app/core/services/parkingTransactionHistory.service';
import { AlertService } from 'src/app/core/alert/alert.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { QueryResult, PageMetaData } from 'src/app/shared/models/queryResult';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/core/alert/dialog.service';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { AddCommentData, AddCommentDialog } from 'src/app/shared/components/addcommentdialog/addcomment.dialog.component';


@Component({
    selector: 'app-parkingtransactionhistorylisting',
    templateUrl: './parkingtransactionhistorylisting.component.html',
    styleUrls: ['./parkingtransactionhistorylisting.component.scss'],
    standalone: false
})
export class ParkingTransactionHistoryListingComponent implements OnInit {
  parkingTransactionService: ParkingTransactionService;
  parkingTransactionHistoryService: ParkingTransactionHistoryService;
  alertService: AlertService;
  dialogService: DialogService;
  dataSource: ParkingTransactionHistory[]  = [];
  dataSoureMetaData: PageMetaData;
  selection = new SelectionModel<ParkingTransactionHistory>(true, []);
  columnsOpened: boolean;
  defaultSettings: any;
  dialog: MatDialog;
  activatedRoute: ActivatedRoute;
  totalAmountPayable: string;
  totalAvailableCreditNote: string;
  parkingTransactionReferenceId: string;
  customerReferenceId: string;
  referenceId: string;
  vehicleNo: string;
  parkingLocation: string;
  parkingProvider: string;
  transactionStatus: string;
  paymentMeans: string;
  modifiedBy: string;
  cancelStatus: string;

  private fetchDataProcessId = 1;
  public keywordText: string;
  public isLoadingResults = false;
  public parkingTransaction: ParkingTransactionDetail = new ParkingTransactionDetail();
  private filterChangedTimeout;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('parkingDate')  parkingDate: MatDatepicker<Moment>;
  @ViewChild('modifiedDate')  modifiedDate: MatDatepicker<Moment>;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  constructor(parkingTransactionService: ParkingTransactionService, 
              parkingTransactionHistoryService: ParkingTransactionHistoryService,
              alertService: AlertService,
              dialog: MatDialog,
              activatedRoute: ActivatedRoute,
              dialogService: DialogService
              ) { 
    this.defaultSettings = environment.defaultSettings;
    this.parkingTransactionService = parkingTransactionService;
    this.parkingTransactionHistoryService = parkingTransactionHistoryService;
    this.alertService = alertService;
    this.dialogService = dialogService;
    this.dialog = dialog;
    this.activatedRoute = activatedRoute;
  }

  ngOnInit() {
    this.parkingTransactionReferenceId = this.activatedRoute.snapshot.params[`parkingTransactionReferenceId`];
    this.customerReferenceId = this.activatedRoute.snapshot.params[`customerReferenceId`];
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
  }

  keywordChanged(event: KeyboardEvent) {

    if (this.filterChangedTimeout) {
      clearTimeout(this.filterChangedTimeout);
    }

    if (event.keyCode === 13) {
      this.fetchData(this.paginator.pageSize, 1);
      return;
    }

    this.filterChangedTimeout = setTimeout(() => {
        this.fetchData(this.paginator.pageSize, 1);
    }, 1000);
  }
  filterDropdownChanged(){
    this.fetchData(this.paginator.pageSize, 1);
  }

  parkingDateChanged() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  modifiedDateChanged() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  fetchData(pageSize: number, pageNumber: number) {
    const fetchDataProcessId = this.fetchDataProcessId + 1;
    this.fetchDataProcessId = fetchDataProcessId;

    this.isLoadingResults = true;
    if (this.cancelStatus === `Default`) {
      this.cancelStatus = null;
    }
    this.parkingTransactionService.getParkingTransaction(this.parkingTransactionReferenceId)
      .subscribe(result => {
        this.parkingTransaction = result;
      });
    this.parkingTransactionHistoryService.getAll(this.parkingTransactionReferenceId,
      pageSize, pageNumber)
      .subscribe(result => {
        if(this.fetchDataProcessId != fetchDataProcessId){
            return; //skip when the processid is old
        }

        this.isLoadingResults = false;
        this.dataSource = result.items;
        this.dataSoureMetaData = result.pageMetaData;

        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  paginationChaged(){
    // this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  AddCreditNote() {
    this.alertService.error($localize`To be implemented`);
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `Start`, displayText: $localize`Start` },
    { visible: true, alwaysVisibility: true, fieldName: `End`, displayText: $localize`End` },
    { visible: true, alwaysVisibility: false, fieldName: `Duration`, displayText: $localize`Duration` },
    { visible: true, alwaysVisibility: false, fieldName: `Status`, displayText: $localize`Status` },
    { visible: true, alwaysVisibility: false, fieldName: `PaymentMeans`, displayText: $localize`Payment Mean` },
    { visible: true, alwaysVisibility: false, fieldName: `Amount`, displayText: $localize`Amount` },
    { visible: true, alwaysVisibility: false, fieldName: `CCHolder`, displayText: $localize`CC Holder` },
    { visible: true, alwaysVisibility: false, fieldName: `CCNo`, displayText: $localize`CC Number` },
    { visible: true, alwaysVisibility: false, fieldName: `ExpiryDate`, displayText: $localize`CC Expiry Date` },
    { visible: true, alwaysVisibility: false, fieldName: `ModifiedBy`, displayText: $localize`Modified By` },
    { visible: true, alwaysVisibility: false, fieldName: `ModifiedDate`, displayText: $localize`Modified Date` },
    { visible: true, alwaysVisibility: false, fieldName: `Cancelled`, displayText: $localize`Cancelled` },
    { visible: true, alwaysVisibility: false, fieldName: `Refunded`, displayText: $localize`Refunded` },
    { visible: true, alwaysVisibility: false, fieldName: `Comment`, displayText: $localize`Comment` }
  ];

  cancelTransaction(transaction: ParkingTransactionDetail)
  {
    let title ="";
    let status = this.toTitleCase(transaction.status);

    if (transaction.status == "PAYABLE")
    {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. This amount won't be charged to the customer.`;
    }
    
    const dialogRef = this.dialog.open<AddCommentDialog, AddCommentData, AddCommentData>(AddCommentDialog,{
      width: '550px',
      data: { comment:'',title: title}
    });

    return dialogRef.afterClosed().subscribe(result => {
      if(!result)
      {
        return;
      }

      this.parkingTransactionService.cancelTransaction(transaction.parkingTransactionReferenceID, result.comment)
          .subscribe(result => {
            if (result.success) {
              this.alertService.success($localize`Transaction has been cancelled successfully.`);
              this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
            }
            else {
              this.alertService.error(result.error);
            }
          });
    });
  }

  withdrawTransaction(transaction: ParkingTransactionDetail)
  {
    let title="";
    let status = transaction.status == "PARTIALLYCOLLECTED" ? "Partially Collected": this.toTitleCase(transaction.status);

    if (transaction.status == "COLLECTED" && transaction.paymentMeansPayable == 0 && transaction.deductedCreditNote == 0)
    {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. No amount will be refunded on this transaction because the amount is CHF 0.-`;
    }
    else if (transaction.status == "COLLECTED" && (transaction.paymentMeansPayable > 0 && transaction.deductedCreditNote == 0))
    {
      title = $localize`You are withdrawing ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of ${transaction.paymentMeansPayable} CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText}.`;
    }
    else if ((transaction.status =="PARTIALLYCOLLECTED"))
    {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. The cancelled credit note amount ${transaction.deductedCreditNote} CHF will be set back to credit note's balance. The remaining payable part will be cancelled. This amount won't be charged to the customer.`;
    }
    else if ((transaction.status == "COLLECTED" && transaction.paymentMeansPayable == 0))
    {
      title = $localize`You are withdrawing ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of ${transaction.deductedCreditNote} CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText}.`;
    }
    else if (transaction.status == "COLLECTED" && (transaction.paymentMeansPayable > 0 && transaction.deductedCreditNote > 0))
    {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of ${transaction.paymentMeansPayable} CHF will be refunded to your ${transaction.meansOfPaymentDisplayText} and the amount of ${transaction.deductedCreditNote} CHF will be set back to your credit note balance.`;
    }

    const dialogRef = this.dialog.open<AddCommentDialog, AddCommentData, AddCommentData>(AddCommentDialog,{
      width: '550px',
      data: { comment:'',title: title }
    });

    return dialogRef.afterClosed().subscribe(result => {

      if(!result)
      {
        return;
      }

      this.parkingTransactionService.cancelTransaction(transaction.parkingTransactionReferenceID, result.comment)
          .subscribe(result => {
            if (result.success) {
              this.alertService.success($localize`Transaction has been withdrawn successfully.`);
              this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
            }
            else {
              this.alertService.error(result.error);
            }
          });
    });
  }

  toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
  }

  toolTipText(transaction: ParkingTransactionDetail){
    let toolTipString ="";

    if (transaction.status == "COLLECTED" && transaction.paymentMeansPayable == 0 && transaction.deductedCreditNote == 0)
    {
      toolTipString = $localize`Withdraw Transaction: No amount will be refunded on this transaction.`;
    }
    else if (transaction.status == "PAYABLE")
    {
      toolTipString = $localize`Cancel Parking: This amount won't be charged to the customer.`;
    }
    else if (transaction.status == "COLLECTED" && (transaction.paymentMeansPayable > 0 && transaction.deductedCreditNote == 0))
    {
      toolTipString = $localize`Withdraw Transaction: The amount of ${transaction.paymentMeansPayable} CHF will be refunded to the customer's ${transaction.meansOfPaymentDisplayText} within the next few hours.`;
    }
    else if ((transaction.status =="PARTIALLYCOLLECTED"))
    {
      toolTipString = $localize`Withdraw Transaction: The used credit note amount ${transaction.deductedCreditNote} CHF amount will be set back to the credit note balance.`;
    }
    else if ((transaction.status == "COLLECTED" && transaction.paymentMeansPayable == 0))
    {
      toolTipString = $localize`Withdraw Transaction: The used credit note amount ${transaction.deductedCreditNote} CHF amount will be set back to the credit note balance.`;
    }
    else if (transaction.status == "COLLECTED" && (transaction.paymentMeansPayable > 0 && transaction.deductedCreditNote > 0))
    {
      toolTipString = $localize`Withdraw Transaction: The amount of ${transaction.paymentMeansPayable} CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText} and the amount of ${transaction.deductedCreditNote} CHF will be set back to the credit note balance.`;
    }

    return toolTipString;
  }
}

export interface ParkingTransactionHistory {
  position: number;
  parkingTransactionReferenceID: string;
  parkingStart: string;
  parkingEnd: string;
  durationInSeconds: number;
  durationText: string;
  status: string;
  meansOfPaymentDisplayText: string;
  amount: number;
  holderName: string;
  cardNumber: string;
  expiryDateText: string;
  modifiedBy: string;
  modifiedDate: string;
  isCancelled: boolean;
  isRefunded: boolean;
  comment: string;
}

export class ParkingTransactionDetail {
  parkingTransactionReferenceID: string;
  customerReferenceID: string;
  carPlateNumber: string;
  vehicleType: string;
  parkingLocationDescription: string;
  parkingLocationCoordinates: string;
  meansOfPaymentDisplayText: string;
  paymentMeanNumberDisplayText: string;
  transactionDate: string;
  transactionDateText: string;
  parkingStart: string;
  parkingEnd: string;
  durationInSeconds: number;
  parkingCurrency: string;
  parkingProviderID: string;
  status: string;
  transactionStatus: string;
  modifiedBy: string;
  modifiedDate: string;
  customerName: string;
  tcsMemberNumber: string;
  
  payable: number;
  paymentMeansPayable: number;
  deductedCreditNote: number;
  geoFeatureLatitude: string;
  geoFeatureLongitude: string;
}

export interface TableColumn {
  visible: boolean;
  alwaysVisibility: boolean;
  fieldName: string;
  displayText: string;
}
