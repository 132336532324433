import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { User } from './shared/models/user';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AlertService } from './core/alert/alert.service';
import { RoutePermissionChecker } from './app-routing.module';
import { MsalService } from '@azure/msal-angular';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ReportService } from 'src/app/core/services/report.service';
import { EnvironmentEnum } from './shared/enums/environmentsEnum';
import { DialogService } from 'src/app/core/alert/dialog.service';
import moment from 'moment';
import { ExportMarketingReportComponent } from './shared/components/export-marketing-report/export-marketing-report.component';
import {
  AuthenticationResult,
  AccountInfo,
  EventMessage,
  EventType,
  IPublicClientApplication,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  private router: Router;
  route: string;
  loggedIn: boolean;
  account: any;
  userName: string;
  opened: boolean;
  activeUser: User;
  darktheme: boolean;
  isLocal: boolean;
  isReportVisible: boolean;
  copyrightYear: number;
  broadcastSubscription: Subscription;
  loginPopupSubscription: Subscription;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject(LOCALE_ID) public locale: string,
    router: Router,
    snackBar: MatSnackBar,
    alertService: AlertService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private toastr: ToastrService,
    private exportReportDialog: MatDialog,
    private customerParkingTransactionReportDialog: MatDialog,
    private reportService: ReportService,
    private dialogService: DialogService,
    private dialog: MatDialog
  ) {
    this.isLocal = environment.isLocal;
    this.router = router;
    this.route = this.router.url;
    this.router.events.subscribe((e) => this.updateRoute(this.router.url));
    this.darktheme = localStorage.getItem('tcs-admingui-darktheme') === 'true';
    this.copyrightYear = new Date().getFullYear();
    matIconRegistry.addSvgIcon('fr_flag', domSanitizer.bypassSecurityTrustResourceUrl('../assets/fr_flag.svg'));
    matIconRegistry.addSvgIcon('de_flag', domSanitizer.bypassSecurityTrustResourceUrl('../assets/de_flag.svg'));
    matIconRegistry.addSvgIcon('uk_flag', domSanitizer.bypassSecurityTrustResourceUrl('../assets/uk_flag.svg'));

    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';

    alertService.getMessage().subscribe((message) => {
      if (message) {
        snackBar.open(message.text, undefined, config);
      }
    });

    if (this.authService.instance.getAllAccounts()[0]) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false || this.isLocal;
    }
  }

  displayLokaliseToolbar: boolean = false;

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.broadcastSubscription = this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.authService.instance.getActiveAccount();
    }

    if (activeAccount && activeAccount.name) {
      const splitName = activeAccount.name.split(' ');
      this.userName = splitName[splitName.length - 1];
    }
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.loginPopupSubscription = this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.loginPopupSubscription = this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    const activeAccount = this.authService.instance.getActiveAccount() || this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    if (this.loginPopupSubscription) {
      this.loginPopupSubscription.unsubscribe();
    }

    this.broadcastSubscription.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  updateRoute(val) {
    this.route = val;
  }
  darkModeChange(event) {
    localStorage.setItem('tcs-admingui-darktheme', `${this.darktheme}`);
  }

  navigate(path: string) {
    this.router.navigateByUrl(path);
    this.opened = false;
  }

  hasAccess(path: string) {
    return RoutePermissionChecker.hasAccessInRoutePath(path, this.activeUser.role);
  }

  exportRevenueReport() {
    this.reportService.exportRevenueReport();
  }

  exportFailedCustomerParkingTransactionReport() {
    this.reportService.exportFailedCustomerParkingTransactionReport();
  }

  exportCustomerParkingTransactionReport() {
    this.reportService.exportCustomerParkingTransactionReport(this.locale);
  }

  toggleReport() {
    this.isReportVisible = !this.isReportVisible;
  }

  getDate() {
    let date = new Date(),
      month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let dateString = [day, month, year].join('');

    let hours = date.getHours();
    let minutes = date.getMinutes();

    let hoursString = hours.toString();
    let minutesString = minutes.toString();

    if (hours < 10) hoursString = '0' + hours;

    if (minutes < 10) minutesString = '0' + minutes;

    dateString = `${dateString}_${hoursString}${minutesString}`;
    return dateString;
  }

  exportMarketingReport() {
    this.reportService.exportMarketingReport();
  }
}
