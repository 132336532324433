import { Component, OnInit, ViewChild, Input, Inject, Injectable, Output, EventEmitter } from '@angular/core';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import { environment } from 'src/environments/environment';
import { CustomerService } from 'src/app/core/services/customer.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { ParkingTransaction } from 'src/app/shared/models/parkingTransaction';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { BlockingHistory } from 'src/app/shared/models/blockingHistory';

@Component({
    selector: 'app-blockinghistory',
    templateUrl: './blockinghistory.component.html',
    styleUrls: ['./blockinghistory.component.scss'],
    standalone: false
})
export class BlockinghistoryComponent implements OnInit {
  dataSource: ParkingTransaction[] = [];
  private selection = new SelectionModel<BlockingHistory>(true, []);
  defaultSettings: any;

  isLoadingResults = false;

  @Input()
  customerReferenceId: string;

  @ViewChild(ColumnSelectorComponent) public columnSelector: ColumnSelectorComponent;

  public modifiedDateValue;

  constructor(
    private customerService: CustomerService) {
    this.defaultSettings = environment.defaultSettings;
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.isLoadingResults = true;

    this.customerService.blockingHistory(this.customerReferenceId).then(r => {
      this.dataSource = r.items;
      this.isLoadingResults = false;
    });
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `Date`, displayText: $localize`Date` },
    { visible: true, alwaysVisibility: false, fieldName: `Time`, displayText: $localize`Time` },
    { visible: true, alwaysVisibility: false, fieldName: `Status`, displayText: $localize`Status` },
    { visible: true, alwaysVisibility: false, fieldName: `Comment`, displayText: $localize`Comment` },
    { visible: true, alwaysVisibility: false, fieldName: `FinmaRegulation`, displayText: $localize`FINMA Regulation` },
    { visible: true, alwaysVisibility: false, fieldName: `TransactionAmount`, displayText: $localize`Total Transaction Amount for the Last 365 Days` },
    { visible: true, alwaysVisibility: false, fieldName: `ModifiedBy`, displayText: $localize`Modified By` },
  ];
}
