import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from 'src/app/core/services/customer.service';
import { AlertService } from 'src/app/core/alert/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CustomerInformation } from 'src/app/shared/models/customerInformation';
import {
  AddCreditNoteDialog,
  AddCreditNoteData,
} from 'src/app/shared/components/addcreditnotedialog/addcreditnote.dialog.compoment';
import { creditNoteService } from 'src/app/core/services/creditNote.service';
import { ParkingTransactionListingComponent } from '../parkingtransactionlisting/parkingtransactionlisting.component';
import { MatTabGroup } from '@angular/material/tabs';
import { CreditNoteListingComponent } from '../creditnotelisting/creditnotelisting.component';
import { CollectivepaymentlistingComponent } from '../collectivepaymentlisting/collectivepaymentlisting.component';
import { AddblockcommentdialogComponent } from 'src/app/shared/components/addblockcommentdialog/addblockcommentdialog.component';
import { BlockinghistoryComponent } from '../blockinghistory/blockinghistory.component';

@Component({
    selector: 'app-customerinformation',
    templateUrl: './customerinformation.component.html',
    styleUrls: ['./customerinformation.component.scss'],
    standalone: false
})
export class CustomerInformationComponent implements OnInit {
  @ViewChild(ParkingTransactionListingComponent) parkingTransactionListing: ParkingTransactionListingComponent;
  @ViewChild(CreditNoteListingComponent) creditNoteListingComponent: CreditNoteListingComponent;
  @ViewChild(CollectivepaymentlistingComponent) collectivePaymentComponent: CollectivepaymentlistingComponent;
  @ViewChild(BlockinghistoryComponent) blockingHistoryComponent: BlockinghistoryComponent;
  @ViewChild('customerTabs') customerTabs: MatTabGroup;

  columnsOpened: boolean;
  defaultSettings: any;
  customerReferenceId: string;
  status: string;
  blockingHistory: string;

  private parkingTabIndex = 0;
  private creditNoteTabIndex = 1;
  private collectivePaymentTabIndex = 2;
  private blockingHistoryTabIndex = 3;

  public customer: CustomerInformation = new CustomerInformation();

  constructor(
    private customerService: CustomerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private creditNoteService: creditNoteService
  ) {}

  ngOnInit() {
    this.customerReferenceId = this.activatedRoute.snapshot.params['customerReferenceId'];
    this.fetchCustomer();
    this.blockingHistory = $localize`Blocking History`;
  }

  fetchCustomer() {
    this.customerService.getCustomer(this.customerReferenceId).subscribe((result) => {
      this.customer = result;
      this.status = result.isBlocked ? $localize`Blocked` : $localize`Unblocked`;
    });
  }

  CancelTransactionSuccess() {
    this.fetchCustomer();
    this.creditNoteListingComponent.reloadPage();
  }

  ClearFilters() {
    if (this.customerTabs.selectedIndex == this.parkingTabIndex) {
      this.parkingTransactionListing.clearFilters();
    } else if (this.customerTabs.selectedIndex == this.creditNoteTabIndex) {
      this.creditNoteListingComponent.clearFilters();
    } else if (this.customerTabs.selectedIndex == this.collectivePaymentTabIndex) {
      this.collectivePaymentComponent.clearFilters();
    }
  }

  ShowColumnSelector() {
    if (this.customerTabs.selectedIndex == this.parkingTabIndex) {
      this.parkingTransactionListing.columnSelector.show();
    } else if (this.customerTabs.selectedIndex == this.creditNoteTabIndex) {
      this.creditNoteListingComponent.columnSelector.show();
    } else if (this.customerTabs.selectedIndex == this.collectivePaymentTabIndex) {
      this.collectivePaymentComponent.columnSelector.show();
    } else if (this.customerTabs.selectedIndex == this.blockingHistoryTabIndex) {
      this.blockingHistoryComponent.columnSelector.show();
    }
  }

  AddCreditNote() {
    const dialogRef = this.dialog.open<AddCreditNoteDialog, AddCreditNoteData, AddCreditNoteData>(AddCreditNoteDialog, {
      width: '300px',
      data: { amount: undefined, reason: '' },
    });

    return dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.creditNoteService
        .addCreditNote(this.customer.customerReferenceID, result.amount, result.reason)
        .subscribe((addResult) => {
          this.alertService.success($localize`Credit Note has been added successfully.`);

          this.customer.totalCreditNote = addResult.totalCreditNote;
          this.customer.totalPayable = addResult.totalPayable;

          this.parkingTransactionListing.reloadPage();
          this.creditNoteListingComponent.reloadPage();
        });
    });
  }

  selectedTabLabel() {
    if (this.customerTabs == undefined) {
      return '';
    }

    if (this.customerTabs._tabs.length == 0) {
      return '';
    }

    return this.customerTabs._tabs.toArray()[this.customerTabs.selectedIndex].textLabel;
  }

  openBlockDialog(): void {
    this.dialog.open(AddblockcommentdialogComponent, {
      width: '720px',
      data: this.customer,
    });
  }
}
