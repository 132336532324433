import { Component, Inject, AfterViewInit } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'dialogservice-dialog',
    templateUrl: './addcreditnote.dialog.compoment.html',
    styleUrls: ['./addcreditnote.dialog.compoment.scss'],
    standalone: false
})
export class AddCreditNoteDialog implements AfterViewInit {
  reasonMaxLength: number = 250;
  pageLoaded = false;
  public addCreditNoteForm: UntypedFormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCreditNoteDialog, AddCreditNoteData>,
    @Inject(MAT_DIALOG_DATA) public data: AddCreditNoteData
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.addCreditNoteForm = this.formBuilder.group({
        amount: ['', [Validators.required, Validators.pattern('[0-9]+(.[0-9][0-9]?)?')]],
        reason: ['', [Validators.required, Validators.maxLength(this.reasonMaxLength)]],
      });
      this.pageLoaded = true;
    }, 0);
  }

  get f() {
    if (this.pageLoaded) {
      return this.addCreditNoteForm.controls;
    }
  }
  onCancelClick(): void {
    this.dialogRef.close(undefined);
  }

  onConfirmClick(): void {
    this.data.amount = this.addCreditNoteForm.value.amount;
    this.data.reason = this.addCreditNoteForm.value.reason;

    if (this.data.reason.trim().length > 0 && this.data.amount > 0) {
      this.dialogRef.close(this.data);
    }
  }
}

export class AddCreditNoteData {
  amount: number;
  reason: string;
}
