import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/core/services/customer.service';
import { BlockingStatus } from '../../enums/blockingStatusEnum';
import { CustomerInformation } from '../../models/customerInformation';
import { UpdateStatus } from '../../models/updateStatus';

@Component({
    selector: 'app-addblockcommentdialog',
    templateUrl: './addblockcommentdialog.component.html',
    styleUrls: ['./addblockcommentdialog.component.scss'],
    standalone: false
})
export class AddblockcommentdialogComponent implements OnInit {
  constructor(
    private customerService: CustomerService,
    public dialogRef: MatDialogRef<CustomerInformation>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerInformation
  ) {}

  ngOnInit(): void {
    // Initialization logic can be added here if needed
  }

  onSubmit(f: NgForm) {
    let obj = new UpdateStatus();
    obj.comment = f.controls['comment'].value;
    obj.status = this.data.isBlocked ? BlockingStatus.Unblocked : BlockingStatus.Blocked;

    if (this.data.isBlocked == false) {
      obj.finmaRegulation = f.controls['finmaRegulation'].value == true;
    }

    this.customerService.updateStatus(obj, this.data.customerReferenceID).then(() => {
      this.dialogRef.close(obj);
    });
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
