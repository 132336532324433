import { Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'dialogservice-dialog',
    templateUrl: './dialogservice.dialog.compoment.html',
    styleUrls: ['./dialogservice.dialog.compoment.css'],
    standalone: false
})
export class DialogServiceDialog {
    constructor(
        public dialogRef: MatDialogRef<DialogServiceDialog, string>,
        @Inject(MAT_DIALOG_DATA) public data: DialogServiceData) {}

    onButtonClick(button:string): void {
        this.dialogRef.close(button);
    }
}

export class DialogServiceData {
    title: string;
    message: string;
    icon: string;
    buttons: string[];
}