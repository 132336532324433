import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { ParkingTransactionService } from 'src/app/core/services/parkingTransaction.service';
import { CustomerService } from 'src/app/core/services/customer.service';
import { AlertService } from 'src/app/core/alert/alert.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/core/alert/dialog.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ParkingTransaction } from 'src/app/shared/models/parkingTransaction';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { CustomerInformation } from 'src/app/shared/models/customerInformation';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { LookupService, LookupItem } from 'src/app/core/services/lookup.service';
import {
  AddCommentData,
  AddCommentDialog,
} from 'src/app/shared/components/addcommentdialog/addcomment.dialog.component';
import { EnumDropdown } from 'src/app/shared/models/enumDropdown';
import { ParkingTransactionListingFilterI } from 'src/app/shared/models/parkingTransactionListingFilter';

@Component({
    selector: 'app-parkingtransactionlisting',
    templateUrl: './parkingtransactionlisting.component.html',
    styleUrls: ['./parkingtransactionlisting.component.scss'],
    standalone: false
})
export class ParkingTransactionListingComponent implements OnInit {
  dataSource: ParkingTransaction[] = [];
  private dataSourceMetaData: PageMetaData;
  private selection = new SelectionModel<ParkingTransaction>(true, []);
  parkingProviderList: LookupItem[];
  parkingTransactionStatus: EnumDropdown[] = [];
  paymentMeanList: EnumDropdown[] = [];
  defaultSettings: any;
  transactionStatus: string;
  paymentMeans: string;
  private cancelStatus: string;
  selectedParkingProvider: string;

  private fetchDataProcessId = 1;
  keywordText: string;
  isLoadingResults = false;
  private customer: CustomerInformation;
  private filterChangedTimeout;
  // public filterForm: any;
  public oldKeywordValue: string;

  public filterForm: FormGroup<ParkingTransactionListingFilterI>;

  @Input()
  customerReferenceId: string;

  @Output()
  onCancelSuccess: EventEmitter<string> = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('parkingDate') parkingDate: MatDatepicker<Moment>;
  @ViewChild('modifiedDate') modifiedDate: MatDatepicker<Moment>;
  @ViewChild(ColumnSelectorComponent) public columnSelector: ColumnSelectorComponent;

  constructor(
    private parkingTransactionService: ParkingTransactionService,
    private customerService: CustomerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private lookupService: LookupService,
    filterFormBuilder: FormBuilder
  ) {
    this.defaultSettings = environment.defaultSettings;

    this.buildFormGroup(filterFormBuilder);
  }

  ngOnInit() {
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
    this.fetchLookup(this.customerReferenceId);
    this.fetchParkingTransactionStatusEnum();
    this.fetchPaymentMeans();
  }

  private buildFormGroup(filterFormBuilder: FormBuilder) {
    this.filterForm = filterFormBuilder.group({
      filterKeyword: new FormControl(''),
      parkingProvider: new FormControl(''),
      parkingDate: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
      status: new FormControl(''),
      paymentMeans: new FormControl(''),
      modifiedDate: filterFormBuilder.group({
        start: new FormControl(''),
        end: new FormControl(''),
      }),
    });
  }

  public handleFilterFormChange() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  public handlePaginationChange() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public clearFilters() {
    this.filterForm.get('filterKeyword').setValue('');
    this.filterForm.get('parkingProvider').setValue('');
    this.clearDate('parkingDate');
    this.filterForm.get('status').setValue('');
    this.filterForm.get('paymentMeans').setValue('');
    this.clearDate('modifiedDate');

    this.handleFilterFormChange();
  }

  public clearDate(key: string) {
    this.filterForm.get(key).get('start').setValue('');
    this.filterForm.get(key).get('end').setValue('');
    this.handleFilterFormChange();
  }

  goToGoogleMapLink(latitude: string, longitude: string) {
    window.open(`http://maps.google.com/maps?z=12&t=m&q=loc:${latitude}+${longitude}`, '_blank');
  }

  keywordChanged(event: KeyboardEvent) {
    if (this.oldKeywordValue == this.keywordText) {
      return;
    }

    this.oldKeywordValue = this.keywordText;

    if (this.filterChangedTimeout) {
      clearTimeout(this.filterChangedTimeout);
    }

    if (event.keyCode === 13) {
      this.fetchData(this.paginator.pageSize, 1);
      return;
    }

    this.filterChangedTimeout = setTimeout(() => {
      this.fetchData(this.paginator.pageSize, 1);
    }, 1000);
  }

  reloadPage() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  fetchData(pageSize: number, pageNumber: number) {
    const parkingProvider = this.filterForm.value.parkingProvider;

    const parkingDateStart = this.formatDate(this.filterForm.value.parkingDate.start);
    const parkingDateEnd = this.formatDate(this.filterForm.value.parkingDate.end);

    const modifiedDateStart = this.formatDate(this.filterForm.value.modifiedDate.start);
    const modifiedDateEnd = this.formatDate(this.filterForm.value.modifiedDate.end);

    const fetchDataProcessId = this.fetchDataProcessId + 1;
    this.fetchDataProcessId = fetchDataProcessId;

    this.isLoadingResults = true;
    if (this.cancelStatus === `Default`) {
      this.cancelStatus = null;
    }

    this.parkingTransactionService
      .getAll(
        this.keywordText,
        parkingProvider,
        parkingDateStart,
        parkingDateEnd,
        this.paymentMeans,
        this.transactionStatus,
        modifiedDateStart,
        modifiedDateEnd,
        this.customerReferenceId,
        pageSize,
        pageNumber
      )
      .subscribe((result) => {
        if (this.fetchDataProcessId != fetchDataProcessId) {
          return; //skip when the processid is old
        }

        this.isLoadingResults = false;
        this.dataSource = result.items;
        this.dataSourceMetaData = result.pageMetaData;

        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  /**
   * Type guard for distinguishing between `string` and `Moment` types.
   *
   * @param value the value to check
   * @returns boolean indicating whether the variable is a Moment
   */
  private isMoment(value: string | Moment): value is Moment {
    return typeof value !== 'string';
  }

  private formatDate(date: string | Moment) {
    if (!this.isMoment(date)) {
      return date;
    }
    return date.format(environment.defaultSettings.dateMomentFormat);
  }

  fetchLookup(customerReferenceID: string) {
    this.lookupService.getParkingProviders(customerReferenceID).subscribe((result) => {
      this.parkingProviderList = result;
    });
  }

  fetchParkingTransactionStatusEnum() {
    this.parkingTransactionService.getParkingTransactionStatus().subscribe((result) => {
      this.parkingTransactionStatus = result.items;
    });
  }

  fetchPaymentMeans() {
    this.parkingTransactionService.getPaymentMeans().subscribe((result) => {
      this.paymentMeanList = result.items;
    });
  }

  paginationChaged() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `ParkingReferenceID`, displayText: $localize`Reference ID` },
    { visible: true, alwaysVisibility: true, fieldName: `VehicleNumber`, displayText: $localize`License Plate` },
    { visible: true, alwaysVisibility: true, fieldName: `VehicleType`, displayText: $localize`Vehicle Type` },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `ParkingLocationDescription`,
      displayText: $localize`Parking Location`,
    },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `ParkingLocationCoordinate`,
      displayText: $localize`Coordinates`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingProvider`, displayText: $localize`Parking Provider` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingStartDate`, displayText: $localize`Start Date` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingStart`, displayText: $localize`Start Time` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingEndDate`, displayText: $localize`End Date` },
    { visible: true, alwaysVisibility: false, fieldName: `ParkingEnd`, displayText: $localize`Parking End` },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `TotalParkingDuration`,
      displayText: $localize`Total Duration`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `Status`, displayText: $localize`Status` },
    { visible: true, alwaysVisibility: false, fieldName: `PaymentMeans`, displayText: $localize`Payment Mean` },
    {
      visible: true,
      alwaysVisibility: false,
      fieldName: `PaymentMeanNumber`,
      displayText: $localize`Credit Card Number`,
    },
    { visible: true, alwaysVisibility: false, fieldName: `Amount`, displayText: $localize`Amount` },
    { visible: true, alwaysVisibility: false, fieldName: 'Payable', displayText: $localize`Payable` },
    { visible: true, alwaysVisibility: false, fieldName: 'Comment', displayText: $localize`Comment` },
    { visible: true, alwaysVisibility: false, fieldName: `ModifiedBy`, displayText: $localize`Modified By` },
    { visible: true, alwaysVisibility: false, fieldName: `ModifiedDate`, displayText: $localize`Modified Date` },
    { visible: true, alwaysVisibility: true, fieldName: `Actions`, displayText: $localize`Actions` },
  ];

  cancelTransaction(transaction: ParkingTransaction) {
    let title = '';
    let status = this.toTitleCase(transaction.status);

    if (transaction.status == 'PAYABLE') {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. This amount won't be charged to the customer.`;
    }

    const dialogRef = this.dialog.open<AddCommentDialog, AddCommentData, AddCommentData>(AddCommentDialog, {
      width: '550px',
      data: { comment: '', title: title },
    });

    return dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.parkingTransactionService
        .cancelTransaction(transaction.parkingTransactionReferenceID, result.comment)
        .subscribe((result) => {
          if (result.success) {
            this.alertService.success($localize`Transaction has been cancelled successfully.`);
            this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
            this.onCancelSuccess.emit(transaction.parkingTransactionReferenceID);
          } else {
            this.alertService.error(result.error);
          }
        });
    });
  }

  withdrawTransaction(transaction: ParkingTransaction) {
    let title = '';
    let status =
      transaction.status == 'PARTIALLYCOLLECTED' ? 'Partially Collected' : this.toTitleCase(transaction.status);

    if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable == 0 &&
      transaction.deductedCreditNote == 0
    ) {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. No amount will be refunded on this transaction because the amount is CHF 0.-`;
    } else if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable > 0 &&
      transaction.deductedCreditNote == 0
    ) {
      title = $localize`You are withdrawing ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of ${transaction.paymentMeansPayable} CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText}.`;
    } else if (transaction.status == 'PARTIALLYCOLLECTED') {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. The cancelled credit note amount ${transaction.deductedCreditNote} CHF will be set back to credit note's balance. The remaining payable part will be cancelled. This amount won't be charged to the customer.`;
    } else if (transaction.status == 'COLLECTED' && transaction.paymentMeansPayable == 0) {
      title = $localize`You are withdrawing ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of  ${transaction.deductedCreditNote}CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText}.`;
    } else if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable > 0 &&
      transaction.deductedCreditNote > 0
    ) {
      title = $localize`You are cancelling ${transaction.parkingTransactionReferenceID} with the Status ${status}. The amount of ${transaction.paymentMeansPayable} CHF will be refunded to your ${transaction.meansOfPaymentDisplayText} and the amount of ${transaction.deductedCreditNote} CHF will be set back to your credit note balance.`;
    }

    const dialogRef = this.dialog.open<AddCommentDialog, AddCommentData, AddCommentData>(AddCommentDialog, {
      width: '550px',
      data: { comment: '', title: title },
    });

    return dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.parkingTransactionService
        .cancelTransaction(transaction.parkingTransactionReferenceID, result.comment)
        .subscribe((result) => {
          if (result.success) {
            this.alertService.success($localize`Transaction has been withdrawn successfully.`);
            this.fetchData(this.paginator.pageSize, this.paginator.pageIndex + 1);
            this.onCancelSuccess.emit(transaction.parkingTransactionReferenceID);
          } else {
            this.alertService.error(result.error);
          }
        });
    });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  toolTipText(transaction: ParkingTransaction) {
    let toolTipString = '';

    if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable == 0 &&
      transaction.deductedCreditNote == 0
    ) {
      toolTipString = $localize`Withdraw Transaction: No amount will be refunded on this transaction.`;
    } else if (transaction.status == 'PAYABLE') {
      toolTipString = $localize`Cancel Parking: This amount won't be charged to the customer.`;
    } else if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable > 0 &&
      transaction.deductedCreditNote == 0
    ) {
      toolTipString = $localize`Withdraw Transaction: The amount of ${transaction.paymentMeansPayable} CHF will be refunded to the customer's ${transaction.meansOfPaymentDisplayText} within the next few hours.`;
    } else if (transaction.status == 'PARTIALLYCOLLECTED') {
      toolTipString = $localize`Withdraw Transaction: The used credit note amount ${transaction.deductedCreditNote} CHF amount will be set back to the credit note balance.`;
    } else if (transaction.status == 'COLLECTED' && transaction.paymentMeansPayable == 0) {
      toolTipString = $localize`Withdraw Transaction: The used credit note amount ${transaction.deductedCreditNote} CHF amount will be set back to the credit note balance.`;
    } else if (
      transaction.status == 'COLLECTED' &&
      transaction.paymentMeansPayable > 0 &&
      transaction.deductedCreditNote > 0
    ) {
      toolTipString = $localize`Withdraw Transaction: The amount of ${transaction.paymentMeansPayable} CHF will be refunded to customer's ${transaction.meansOfPaymentDisplayText} and the amount of ${transaction.deductedCreditNote} CHF will be set back to the credit note balance.`;
    }

    return toolTipString;
  }
}
