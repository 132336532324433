import { Component, OnInit, ViewChild } from '@angular/core';
import { CreditNotePromotionService } from 'src/app/core/services/creditnotepromotion.service';
import { CreditNotePromotionPageItem } from 'src/app/shared/models/creditnotePromotionPageItem';
import { CreditnotePromotionsCustomersComponent } from 'src/app/components/creditnotepromotionscustomers/creditnotepromotionscustomers.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { WithdrawPromotionDialogComponent } from 'src/app/shared/components/withdrawpromotiondialog/withdrawpromotiondialog.component';
import { ReportService } from 'src/app/core/services/report.service';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'creditnotepromotionsinfo',
    templateUrl: './creditnotepromotionsinfo.component.html',
    styleUrls: ['./creditnotepromotionsinfo.component.scss'],
    standalone: false
})
export class CreditnotePromotionsInfoComponent implements OnInit {
  @ViewChild(CreditnotePromotionsCustomersComponent) creditnotePromotionsCustomersComponent: CreditnotePromotionsCustomersComponent;
  public isLoadingResults = false;
  creditnotepromotion: CreditNotePromotionPageItem = new CreditNotePromotionPageItem();
  public creditNotePromotionService: CreditNotePromotionService;
  promotionId:string;


  constructor(
    creditNotePromotionService: CreditNotePromotionService,
    private reportService: ReportService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    ) 
  {
    this.creditNotePromotionService = creditNotePromotionService;
    this.promotionId = this.activatedRoute.snapshot.params['promotionId']
  }

  ngOnInit() {
    this.fetchData(this.promotionId);
  }

  ShowColumnSelector() {
    this.creditnotePromotionsCustomersComponent.columnSelector.show()
  }

  withdrawPromo() {
    const dialogRef = this.dialog.open(WithdrawPromotionDialogComponent, {
      width: '720px',
      data: this.creditnotepromotion
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  fetchData(promotionid) {

    this.isLoadingResults = true;

    this.creditNotePromotionService.getPromotion(promotionid)
      .subscribe(result => {
        this.isLoadingResults = false;
        this.creditnotepromotion = result;
      });
  }

  createReport() {
   this.reportService.exportCreditNotePromotionsCustomersReport(this.promotionId);
  }
}