import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AlertService } from '../alert/alert.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient, private alertService: AlertService, private toastr: ToastrService, private authenticationService: AuthenticationService) {
  }

  public exportRevenueReport() {
    let url = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/report/revenuesummary`;
    this.downloadCSV(url, "revenuesummary");
  }

  public exportCustomerParkingTransactionReport(culture: string) {
    let url = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/report/customerparkingtransaction/${culture}`;
    this.downloadCSV(url, "customerparkingtransaction");
  }

  public exportFailedCustomerParkingTransactionReport() {
    let url = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/report/failedcollectivepaymentcustomerparkingtransaction`;
    this.downloadCSV(url, "FailedCustomerParkingTransaction");
  }

  public exportMarketingReport() {
    let url = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/report/marketing`;
    this.downloadCSV(url, "marketing");
  }

  public exportCreditNotePromotionsCustomersReport(promotionId: string) {
    let url = `${environment.backendApiUrl}/v${environment.backendApiVersion}/admingui/creditnotepromotions/${promotionId}/customers/report`;
    this.downloadCSV(url, "CreditNotePromotionsCustomers");
  }


  private downloadCSV(url: string, filename: string) {
    let downloadToastr = this.toastr.success($localize`Download in progress`, $localize`Notification`, {
      disableTimeOut: true
    });
    this.downLoadFile(url, filename);
  }

  private downLoadFile(url: string, filename: string) {
    const token = this.authenticationService.getActiveUser()?.token;
    
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`
    });

    return this.http.get(url, {
      responseType: 'blob',
      headers,
      observe: 'response'
    }).subscribe(res => {
      const blob = new Blob([res.body], { type: res.body.type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;

      link.click();
    });
  }

}