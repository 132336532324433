import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-global-export',
    templateUrl: './global-export.component.html',
    styleUrls: ['./global-export.component.scss'],
    standalone: false
})
export class GlobalExportComponent implements OnInit {

  ngOnInit() {
    // Initialization logic can be added here if needed
  }

}
