import { DatePipe } from '@angular/common';
import { Observable, throwError } from 'rxjs';
import {catchError} from'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { CreditNotePromotionPageItem } from 'src/app/shared/models/creditnotePromotionPageItem';
import { CreditNotePromotionService } from 'src/app/core/services/creditnotepromotion.service';
import { PageMetaData } from 'src/app/shared/models/queryResult';
import { TableColumn } from 'src/app/shared/models/tableColumn';
import { Sort } from '@angular/material/sort';
import { SortDirection } from 'src/app/shared/enums/sortDirectionEnum';
import { ColumnSelectorComponent } from 'src/app/shared/components/columnSelector/columnselector.component';
import { DialogService } from 'src/app/core/alert/dialog.service';
import { AddCreditNotePromotionDialog, AddCreditNotePromotionData } from 'src/app/shared/components/addCreditNotePromotionDialog/addcreditnotepromotion.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/core/alert/alert.service';

@Component({
    providers: [DatePipe],
    selector: 'creditnotepromotions',
    templateUrl: './creditnotepromotions.component.html',
    styleUrls: ['./creditnotepromotions.component.scss'],
    standalone: false
})
export class CreditnotePromotionsComponent implements OnInit {
  dataSource: CreditNotePromotionPageItem[] = [];
  dataSoureMetaData: PageMetaData;
  public isLoadingResults = false;
  public creditNotePromotionService :CreditNotePromotionService;
  sort: string = "CreationDate";
  sortDirection: SortDirection = SortDirection.Descending;
  defaultSettings: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ColumnSelectorComponent) columnSelector: ColumnSelectorComponent;
  public creditnotepromotion: CreditNotePromotionPageItem = new CreditNotePromotionPageItem();

  constructor(
    public datepipe: DatePipe,
    creditNotePromotionService: CreditNotePromotionService,
    private dialog: MatDialog,
    private alertService: AlertService
    ) {
    this.defaultSettings = environment.defaultSettings;
    this.creditNotePromotionService = creditNotePromotionService;
   }
  
  ngOnInit() {
    this.fetchData(environment.defaultSettings.table.pageSize, 1);
  }
  paginationChaged() {
    this.fetchData(this.paginator.pageSize, this.paginator.pageIndex);
  }

  withdrawTransaction(element) {
    this.isLoadingResults = true;
    this.creditNotePromotionService.withdrawPromotion(element.promoId)
    .subscribe(result => {
      this.isLoadingResults = false;
      this.fetchData(environment.defaultSettings.table.pageSize, 1);
    });
  }
  fetchData(pageSize: number, pageNumber: number) {

    this.isLoadingResults = true;

    this.creditNotePromotionService.getAll('',pageSize, pageNumber, this.sort, this.sortDirection)
      .subscribe(result => {
        this.isLoadingResults = false;
        this.dataSource = result.items;
        this.dataSoureMetaData = result.pageMetaData;

        this.paginator.length = result.pageMetaData.totalItemCount;
        this.paginator.pageIndex = result.pageMetaData.pageNumber - 1;
      });
  }

  public defaultColumns: TableColumn[] = [
    { visible: true, alwaysVisibility: true, fieldName: `PromoId`, displayText: $localize`Credit Note ID` },
    { visible: true, alwaysVisibility: false, fieldName: `Name`, displayText: $localize`Name` },
    { visible: true, alwaysVisibility: false, fieldName: `Code`, displayText: $localize`Code` },
    { visible: true, alwaysVisibility: false, fieldName: `Amount`, displayText: $localize`Amount` },
    { visible: true, alwaysVisibility: false, fieldName: `MaxRedemptions`, displayText: $localize`Maximum Number of Redemptions` },
    { visible: true, alwaysVisibility: false, fieldName: `CreationDate`, displayText: $localize`Creation Date` },
    { visible: true, alwaysVisibility: false, fieldName: `DurationStart`, displayText: $localize`Duration Start` },
    { visible: true, alwaysVisibility: false, fieldName: `DurationEnd`, displayText: $localize`Duration End` },
    { visible: true, alwaysVisibility: false, fieldName: `Expiration`, displayText: $localize`Expiration` },
    { visible: true, alwaysVisibility: false, fieldName: `Status`, displayText: $localize`Status` },
  ];

  clearFilters() {
    this.fetchData(this.paginator.pageSize, 1);
  }

  addCreditNotePromo(){
    const dialogRef = this.dialog.open<AddCreditNotePromotionDialog, AddCreditNotePromotionData, AddCreditNotePromotionData>(AddCreditNotePromotionDialog, {
      width: '720px',
      data: { amount: 0, name: '', code: '', expiration: new Date(), durationStart: new Date(), durationEnd: new Date(), maxRedemption: 0 }
    });

    return dialogRef.afterClosed().subscribe(result => {

      if (!result) {
        return;
      }
      
      this.creditnotepromotion.amount = result.amount;
      this.creditnotepromotion.code = result.code;
      this.creditnotepromotion.durationEnd = this.datepipe.transform(result.durationEnd, 'yyyy-MM-dd');
      this.creditnotepromotion.durationStart =this.datepipe.transform(result.durationStart, 'yyyy-MM-dd');
      this.creditnotepromotion.expiration =this.datepipe.transform(result.expiration, 'yyyy-MM-dd');
      this.creditnotepromotion.maxRedemptions = result.maxRedemption;
      this.creditnotepromotion.name = result.name;
      this.creditNotePromotionService.AddPromotion(this.creditnotepromotion)
      .subscribe(addResult => {
          this.alertService.success($localize`Credit Note Promotion has been added successfully.`);
          this.fetchData(this.paginator.pageSize, this.paginator.pageIndex);
        }, (error:any) =>
        {
          console.log(error);
          this.alertService.error($localize`Cannot add a promotion with the same code as an existing one.`);
        });
    });

  }
  sortData(sort: Sort) {
    this.sort = sort.active;
    this.sortDirection = sort.direction == "asc" ? SortDirection.Ascending : SortDirection.Descending;
    this.fetchData(this.paginator.pageSize, 1);
  }
}
